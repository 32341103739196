import { each } from 'lodash';
import axios from 'axios';

interface UseFormKitHelpersOptions {
	submitUrl: string;
}

export interface FormValues {
    [key: string]: FormDataEntryValue;
}


export const SUBMIT_URL = '/api/forms/submit';

function prepareFormData(formValues: FormValues) {
	const bodyFormData = new FormData();

	each(formValues, (value: FormDataEntryValue, key: string) => {
		if (value) {
			bodyFormData.append(key, value);
		}
	});

	return bodyFormData;
}

export const useFormKitHelpers = (options?: UseFormKitHelpersOptions) => {
	const submitUrl = options?.submitUrl ?? SUBMIT_URL;

	async function submitForm<T>(formData: FormData) {
		const response = await axios.post<T>(submitUrl, formData);

		return response;
	}

	return {
		prepareFormData,
		submitForm,
	};
};
