import { createApp } from 'vue';
import store from 'shared/store';
import { initFormKitSchema} from 'shared/utils/initFormKitSchema';
import EasyContactWidget from './easyContactWidget.vue'
import FormKitBuilderContainer from 'shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue'

function mountCommunicationChannel() {
	const easyContactWidgetMount = document.getElementById('easy-contact-widget');

	if(!easyContactWidgetMount) {
		console.warn('[communicationChannel Container not found');
		return;
	}

	const app = createApp({
		name: 'easy-contact widget',
	});

	app.component(
		'easy-contact-widget',
		EasyContactWidget,
	);

	app.component(
		'form-kit-builder-container',
		FormKitBuilderContainer,
	);

	app.use(store);
	initFormKitSchema(app);

	app.mount(easyContactWidgetMount);
}

mountCommunicationChannel();