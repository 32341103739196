import { ref } from 'vue';

interface UseGenesysOptions {
	forceNativeChatButtonVisible?: boolean;
	openPopupCallback?: () => void;
	closePopupCallback?: () => void;
}

const SCRIPT_TAG_QUERY = 'script[src*="genesys"]';
const NATIVE_BUTTON_QUERY = 'iframe#genesys-mxg-frame';
const FORCE_VISIBLE_CLASS = 'chat-genesys-force-visible';

export const messages = {
	noScript: '[chatGenesys]: No genesys script-tag found',
	messengerNotReady: '[chatGenesys]: Genesys messenger is not ready',
	noGenesysFunction: '[chatGenesys]: f:window.Genesys is not available',
} as const;

let genesysScript: HTMLScriptElement | null;
let nativeButton: HTMLIFrameElement | null;

const useGenesysOptions = ref<UseGenesysOptions>();
const isGenesysSet = ref(false);
const isChatOpened = ref(false);

function setOptions(options?: UseGenesysOptions) {
	useGenesysOptions.value = options;
}

export function openChat() {
	setupGenesys();

	if (!isGenesysSet.value) {
		console.warn(messages.messengerNotReady);
		return false;
	}

	if(!window.Genesys) return false;

	window.Genesys('subscribe', 'Messenger.ready', function () {
		window.Genesys('command', 'Messenger.openConversation', {}, useGenesysOptions.value?.openPopupCallback);
	});
	return true;
}
/**
 * Initially the button shall stay hidden, due to the class, which is added to the body (see "_helpers-chat.scss").
 * After first launch however, the button should stay visible
 */
export function forceVisibleNativeButton() {
	nativeButton = document.querySelector<HTMLIFrameElement>(NATIVE_BUTTON_QUERY);
	if (nativeButton) {
		nativeButton.classList.add(FORCE_VISIBLE_CLASS);
	}
}

export function setupGenesys() {
	if (!genesysScript) {
		console.warn(messages.noScript);
		return;
	}

	if (!window.Genesys) {
		console.warn(messages.noGenesysFunction);
		return;
	}

	if (isGenesysSet.value) return;

	window.Genesys('subscribe', 'Messenger.ready', function () {
		isGenesysSet.value = true;
	});

	window.Genesys('subscribe', 'Messenger.opened', function () {
		isChatOpened.value = true;

		if (useGenesysOptions.value?.forceNativeChatButtonVisible) forceVisibleNativeButton();
	});

	window.Genesys('subscribe', 'Messenger.closed', function () {
		isChatOpened.value = false;
		if (useGenesysOptions.value?.closePopupCallback) useGenesysOptions.value.closePopupCallback();
	});

	if(!isGenesysSet.value) {
		isGenesysSet.value = true;
	}
}


/**
 * Composable which implements the Genesys live-chat integration
 *
 * **Resources:**
 *
 * [globalFunction](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/genesysgf)
 *
 * [Build your own Messenger launcher](https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/pluginExamples)
 */

export const useGenesysChat = (options?: UseGenesysOptions) => {
	setOptions(options);

	genesysScript = document.querySelector<HTMLScriptElement>(SCRIPT_TAG_QUERY);

	function init() {
		setupGenesys();
	}

	if (document.readyState == 'complete') {
		init();
	} else {
		window.addEventListener('load', () => {
			init();
		});
	}

	return {
		openChat,
		chatAvailable: isGenesysSet,
		isChatOpened,
	};
};
